import * as React from 'react';
import Layout from '../components/Layout';
import {StaticImage} from 'gatsby-plugin-image';
import BasicTextModule from '../components/BasicTextModule/BasicTextModule';
import Seo from '../components/SEO';
import SimpleBanner from '../components/SimpleBanner/SimpleBanner';

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <SimpleBanner title="Get in touch">
          <StaticImage
            className="banner__image"
            src="../../static/get-in-touch.jpeg"
            alt="A cozy place with a coffee table and two seats"
          />
        </SimpleBanner>
        <BasicTextModule title="Thank you"
          content="Thank you very much for contacting me. I will respond to your message as soon as possible."
          link="/"
          linkText="Back to main page"/>
      </Layout>
    </>
  );
};

export default contact;
